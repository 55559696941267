vb
<template>
  <v-row
    class="fill-height"
    justify="center"
    align="center"
  >
    <v-col
      :lg="4"
      class="px-10 lg:px-0"
    >
      <v-card>
        <v-card-title>{{ COMMON.LABEL_CONFIRM_REGISTRATION }}</v-card-title>
        <v-card-text>
          <v-form
            ref="form"
            @submit.prevent="handleVerifyRegistration"
          >
            <v-row>
              <v-col cols="12">
                <v-btn
                  block
                  class="primary"
                  type="submit"
                  :disabled="tokenHasExpired"
                >
                  <span v-if="!isBusy">{{ COMMON.LABEL_VERIFY }}</span>
                  <v-progress-circular
                    v-else
                    indeterminate
                  />
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
          <v-form
            ref="form"
            @submit.prevent="onSubmit"
          >
            <v-row>
              <v-col
                v-if="tokenHasExpired"
                cols="12"
              >
                <div class="caption mb-2">
                  Your token has expired! Click to resend token
                </div>
                <v-text-field
                  v-model="email"
                  solo
                  placeholder="Enter email to resend token"
                  hide-details="auto"
                  type="email"
                  class="mb-4"
                  :rules="[rules.required, rules.email]"
                />
                <v-btn
                  block
                  class="primary"
                  type="submit"
                >
                  <span v-if="!isBusy">{{ COMMON.LABEL_RESEND_TOKEN }}</span>
                  <v-progress-circular
                    v-else
                    indeterminate
                  />
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-col>

    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      absolute
      right
      top
    >
      {{ snackBarText }}
      <template #action="{ attrs }">
        <v-btn
          color="primary"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          {{ COMMON.CLOSE_BTN }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-row>
</template>

<script>
import common from '@/commons/common';

export default {
  name: 'ConfirmRegistration',

  props: {
    token: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      COMMON: common,
      ICON: common.ICON,
      isBusy: false,

      snackbar: false,
      snackBarText: 'This is default text!',
      timeout: 3000,

      tokenHasExpired: false,
      email: '',

      rules: {
        required: (value) => !!value || 'Required.',
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || 'Invalid e-mail.';
        },
      },
    };
  },

  methods: {
    enableSnackbar(text) {
      this.snackbar = true;
      this.snackBarText = text;
    },

    onSubmit() {
      if (this.$refs.form.validate()) {
        this.handleResendToken();
      }
    },

    async handleVerifyRegistration() {
      try {
        this.isBusy = true;
        const { status } = await this.$axios.get(
          `/secured/registrationConfirm/${this.token}`
        );
        if (status === 200) {
          this.enableSnackbar('Verifed successfully!');
          // this.$router.push('/welcome');
          window.location.href = 'http://newsaudible.in';
        }
      } catch (error) {
        this.enableSnackbar(error.response.data.message);
        if (error.response.data.message === 'Token Expired')
          this.tokenHasExpired = true;
      } finally {
        this.isBusy = false;
      }
    },

    async handleResendToken() {
      try {
        this.isBusy = true;
        const { status } = await this.$axios.get(
          `/secured/resendRegistrationToken?email=${this.email}`
        );
        if (status === 200) {
          this.enableSnackbar('Token resent successfully!');
        }
      } catch (error) {
        this.enableSnackbar(error.response.data.message);
      } finally {
        this.isBusy = false;
      }
    },
  },
};
</script>

<style></style>
